.logoMarkWrapper {
  display: flex;
  border-radius: 10%;
  padding: 8px;
  margin-right: 15px;
  background: rgb(99, 30, 30);
  background: linear-gradient(152deg, rgba(99, 30, 30, 1) 0%, rgba(195, 73, 73, 1) 77%);
  /* min-width: 50px;
  min-height: 50px; */
}

.logoMark {
  max-width: 100%;
}

.logoWrapper {
  margin: 20px 0 30px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
