.header {
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disabledBookings {
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdc9c6;
  color: #cf1322;
  height: 50px;
  font-size: 14px;
}

.innerHeader {
  max-width: 1300px;
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
}

.menu {
  align-items: center;
}

.menuicon {
  vertical-align: -7px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.simpleWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}

.simpleContentInner {
  height: 100%;
}

.content {
  padding: 100px 50px 70px;
  flex-grow: 1;
  width: 100%;
  max-width: 1300px;
  margin: auto;
  background: white;
}

.simpleContent {
  flex-grow: 1;
  max-width: 1300px;
  margin: auto;
}

.footer {
  font-size: 10px;
  color: #333;
  text-align: center;
}
